import { NextPage } from 'next';
import Layout from 'src/common/components/layout/Layout';
import Button from 'react-bootstrap/Button';
import Link from 'next/link';

const Custom404Page: NextPage = () => {
  return (
    <Layout title="404 - not found">
      <div className="main-bg-container error-page d-flex flex-column">
        <div className="error-page-top d-flex align-items-end">
          <div className="error-page-number">404</div>
        </div>
        <div className="error-page-bottom flex-grow-1 d-flex justify-content-end px-5">
          <div className="col-12 col-md-6 text-center">
            <div className="error-img text-center">
              <img src="/img/illu-not-found.svg"></img>
            </div>
            <p className="text-white fs-3 pt-5 pb-2">
              The page you were looking for doesn’t exist
            </p>
            <Link href="/">
              <Button>go to dashboard</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Custom404Page;
