import React, { useRef } from 'react';
import Head from 'next/head';
import TopNav from 'src/common/components/layout/TopNav';

import { useSelector } from 'react-redux';
import { AppState } from 'src/common/store/index';
// import MiniChatApp from '@minichatapp-components/MiniChatApp';
// import { useSession } from 'next-auth/react';
// import { ISession } from '@interfaces/session';
// import { w3cwebsocket as W3CWebSocket } from 'websocket';
// import {
//   deleteChannelMessage,
//   deleteRoomMessage,
//   setChannelUnreadMessages,
//   setChannelUnreadMessagesToNull,
//   setChannelsUserIsIn,
//   setMessageRoomMembers,
//   setRoomUnreadMessage,
//   updateChannelUnreadMessages,
//   updateRoomUnreadMessage,
// } from '@chatapp-store/chatappSlice';
// import { ChatApp } from 'src/chat-app/services';
// import {
//   IMessageResp,
//   IRoomMessageGetDelNotif,
// } from '@chatapp-interfaces/message';
// import { useQuery } from 'react-query';
// import * as Sentry from '@sentry/react';
// import { useQueryClient } from 'react-query';

type Props = {
  children: React.ReactNode;
  title: string;
};

const Layout = ({ title, children }: Props) => {
  // const { data: session } = useSession() as unknown as ISession;

  const dashboard = useSelector((state: AppState) => state.patientFlow);
  const { fullScreen } = dashboard;
  // const { chatAppNavId, messageRoomMembers } = useSelector(
  //   (state: AppState) => state.chatApp,
  // );

  const latestStateRef = useRef<any>();
  // const roomTimestampValue = useRef<string | number | null>(null);
  // const channelTimestampValue = useRef<string | number | null>(null);

  // const queryClient = useQueryClient();

  latestStateRef.current = useSelector((state: AppState) => state.chatApp);

  // const [messageFromWebsocket, setMessageFromWebsocket] = useState<
  //   IMessageResp | IRoomMessageGetDelNotif | null
  // >(null);

  // const dispatch = useDispatch();
  // const chatAppServiceInstance = new ChatApp();
  // const chatappSocketUrl =
  //   process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_SOCKET_URL;
  // const chatappSocketUrl = process.env.NEXT_PUBLIC_CHATAPP_SOCKET_URL;

  // Create User Record
  // useEffect(() => {
  //   const createUser = async () => {
  //     if (!session) return;
  //     await chatAppServiceInstance.createUserRecord({
  //       name: session.user.name,
  //       user_id: session?.userId,
  //     });
  //   };

  //   createUser();
  // }, [session?.userId]);

  // const getUserRooms = async () => {
  //   if (session?.userId) {
  //     const res = await chatAppServiceInstance.getRoomsUserIsIn(
  //       session?.userId,
  //     );
  //     return res;
  //   }
  // };

  // useQuery(['get-user-rooms', session?.userId], () => getUserRooms(), {
  //   onSuccess: (data) => {
  //     dispatch(setMessageRoomMembers(data));
  //     // Create record of unread message count
  //     if (data?.length > 0) {
  //       for (let room of data) {
  //         dispatch(
  //           setRoomUnreadMessage({
  //             room_id: room.room_id,
  //             unread_count: room.count,
  //           }),
  //         );
  //       }
  //     }
  //   },
  //   refetchOnWindowFocus: false,
  // });

  // getting user channels
  // const getUserChannels = async () => {
  //   try {
  //     if (session?.userId) {
  //       const res = await chatAppServiceInstance.getChannelsUserIsIn(
  //         session?.userId,
  //       );
  //       return res;
  //     }
  //   } catch (error) {
  //     Sentry.captureException(error);
  //   }
  // };

  // useQuery(['get-user-channels', session?.userId], () => getUserChannels(), {
  //   onSuccess: (data) => {
  //     if (data && data?.length > 0) {
  //       dispatch(setChannelsUserIsIn(data));

  //       for (let channel of data) {
  //         dispatch(
  //           setChannelUnreadMessages({
  //             channel_id: channel.channel_id,
  //             unread_count: channel.count,
  //           }),
  //         );
  //       }
  //     } else {
  //       dispatch(setChannelUnreadMessagesToNull());
  //       dispatch(setChannelsUserIsIn([]));
  //     }
  //   },
  //   refetchOnWindowFocus: false,
  // });

  /**
   * To deal with the duplication of messages from the duplication of subscriptionms on the backend, I'll store the timestamp of incoming notifications and messages. If the incoming timestamp is equal to the stored one, do nothing else run the necessary updates
   */

  // useEffect(() => {
  //   // If the room id is not part of the ones on display, dispatch an action to fetch that room and its details and add it to the array of rooms to be displayed in the navsection
  //   const onMessageEventFunction = async () => {
  //     // If the message coming in is from a private message room
  //     if (messageFromWebsocket?.room_id) {
  //       if (
  //         messageFromWebsocket &&
  //         messageFromWebsocket.type === 'room_notification'
  //       ) {
  //         const room_ids = messageRoomMembers?.filter(
  //           (item) => item.room_id === messageFromWebsocket.room_id,
  //         );

  //         if (
  //           messageFromWebsocket.room_id !== chatAppNavId &&
  //           messageFromWebsocket.type === 'room_notification'
  //         ) {
  //           if (roomTimestampValue.current !== messageFromWebsocket.timestamp) {
  //             if (room_ids?.length === 0) {
  //               // Invalidate the query for fetching rooms so that it can run again
  //               await queryClient.invalidateQueries({
  //                 queryKey: ['get-user-rooms'],
  //               });
  //             } else {
  //               // User is not in the room hence increase the unseen message count for the room
  //               dispatch(updateRoomUnreadMessage(messageFromWebsocket.room_id));
  //             }

  //             // Set the timestampValue to this message's timestamp
  //             roomTimestampValue.current = messageFromWebsocket.timestamp;
  //           }
  //         }
  //       } else if (
  //         messageFromWebsocket &&
  //         messageFromWebsocket.type === 'delete_room_message'
  //       ) {
  //         dispatch(deleteRoomMessage(messageFromWebsocket));
  //       }
  //     }

  //     /**
  //      * Later, i'll have to check if i can move this line =>/timestampValue.current = messageFromWebsocket.timestamp;/ to the after the else if statement for "delete_room_message" so that will be done after all the block have been executed. That line is a security blanket against repeating events.
  //      */

  //     // If the message coming in is from a channel
  //     else if (messageFromWebsocket?.channel_id) {
  //       if (
  //         messageFromWebsocket.channel_id !== chatAppNavId &&
  //         messageFromWebsocket.type === 'channel_notification'
  //       ) {
  //         if (
  //           channelTimestampValue.current !== messageFromWebsocket.timestamp
  //         ) {
  //           dispatch(
  //             updateChannelUnreadMessages(messageFromWebsocket.channel_id),
  //           );
  //         }
  //         channelTimestampValue.current = messageFromWebsocket.timestamp;
  //       } else if (
  //         messageFromWebsocket.type === 'new_user_channel_notification' &&
  //         channelTimestampValue.current !== messageFromWebsocket.timestamp
  //       ) {
  //         await queryClient.invalidateQueries({
  //           queryKey: ['get-user-channels'],
  //         });
  //       } else if (messageFromWebsocket.type === 'delete_channel_message') {
  //         dispatch(deleteChannelMessage(messageFromWebsocket));
  //       }
  //     }

  //     setMessageFromWebsocket(null);
  //   };

  //   onMessageEventFunction();

  //   return () => {
  //     setMessageFromWebsocket(null);
  //   };
  // }, [messageFromWebsocket]);

  // const generalWSRef = useRef<W3CWebSocket | null>(null);

  // useEffect(() => {
  //   generalWSRef.current = session?.userId
  //     ? new W3CWebSocket(`${chatappSocketUrl}/${session?.userId}`)
  //     : null;

  //   // Set Up websocket Connection
  //   function connect() {
  //     if (generalWSRef.current) {
  //       generalWSRef.current.onopen = () => {
  //         console.log('General Chatapp Websocket is open ');
  //       };

  //       generalWSRef.current.onclose = (e: any) => {
  //         console.log(
  //           'Socket is closed. Reconnection will be attempted in 1 second',
  //           e.reason,
  //         );
  //         setTimeout(() => {
  //           connect();
  //         }, 1000);
  //       };

  //       generalWSRef.current.onmessage = async (event: any) => {
  //         const message = JSON.parse(event.data);

  //         // Initiate a state change that will fireoff a separate useEffect. This onmessage event doesnt have access to the chatappslice for some reason. Let that useeffect make the necessary api calls and dispatch the necessary dispatchables
  //         setMessageFromWebsocket(message);
  //       };

  //       generalWSRef.current.onerror = (err) => {
  //         console.log(
  //           'Socket encountered error: ',
  //           err.message,
  //           'Closing socket',
  //         );
  //         if (generalWSRef.current) {
  //           generalWSRef.current.close();
  //         }
  //       };
  //     }
  //   }

  //   connect();

  //   return () => {
  //     if (window !== undefined && generalWSRef.current)
  //       generalWSRef.current.close();
  //   };
  // }, [session?.userId]);

  return (
    <>
      <Head>
        <title>{title} | EHACare</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="shortcut icon" href="/img/favicon-32x32.png" />
        {/* <link rel="stylesheet" type="text/css" href="/styles.css" media="screen"/> */}
      </Head>
      {!fullScreen && <TopNav />}
      <main className="main">
        {children}
        {/* {showMiniChatapp && <MiniChatApp />} */}
      </main>
      {/* {!fullScreen && <Bookmarks />} */}
    </>
  );
};

export default Layout;
